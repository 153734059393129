import './src/styles/global.css';
import ReactGA from 'react-ga4';
import React from 'react';
import { root } from './gatsby-wrapper';

export const wrapRootElement = root;

const trackingId = 'G-QRSCJLN52E'; // Replace with your tracking ID

ReactGA.initialize(trackingId);

export const onRouteUpdate = () => {
  ReactGA.send({ hitType: 'pageview' });
};
